const filterPredefinedFields = (fields, predefinedFields) => {
  return fields.filter(
    (fieldEnum) => predefinedFields.indexOf(fieldEnum) === -1
  )
}

const excludeCompanyForm = (field) =>
  field !== FIELD_TYPE.JobSalaryRangeFieldType

const excludeAccountForm = (field) =>
  field !== FIELD_TYPE.SocialSharingImageFieldType &&
  field !== FIELD_TYPE.CompanyCoverImageFieldType &&
  field !== FIELD_TYPE.JobSalaryRangeFieldType

const excludeJobForm = (field) =>
  field !== FIELD_TYPE.CompanyCoverImageFieldType

export const NEW_FIELD = 'new'
export const NEW_FORM = 'new'

export const USER_FIELDS = 'user-fields'
export const COMPANY_PROFILE_FIELDS = 'company-profile-fields'
export const JOB_POST_FIELDS = 'job-post-fields'

export const GET_FORM = {
  1: COMPANY_PROFILE_FIELDS,
  2: USER_FIELDS,
  3: JOB_POST_FIELDS,
}

export const FORM_METADATA = {
  [COMPANY_PROFILE_FIELDS]: { name: 'Company fields' },
  [USER_FIELDS]: { name: 'User fields' },
  [JOB_POST_FIELDS]: { name: 'Job post fields' },
}

export const FIELD_TYPE = {
  // Predefined Company Profile
  CompanyNameFieldType: 'CompanyNameFieldType',
  CompanyLocationFieldType: 'CompanyLocationFieldType',
  CompanyWebsiteFieldType: 'CompanyWebsiteFieldType',
  CompanyLogoFieldType: 'CompanyLogoFieldType',
  CompanyDescriptionFieldType: 'CompanyDescriptionFieldType',
  // Predefined Job Post
  JobTitleFieldType: 'JobTitleFieldType',
  JobCategoryFieldType: 'JobCategoryFieldType',
  JobTagsFieldType: 'JobTagsFieldType',
  JobLocationFieldType: 'JobLocationFieldType',
  JobApplicationMethodFieldType: 'JobApplicationMethodFieldType',
  JobDescriptionFieldType: 'JobDescriptionFieldType',
  // Standard types,
  TextFieldType: 'TextFieldType',
  TextAreaFieldType: 'TextAreaFieldType',
  RichTextFieldType: 'RichTextFieldType',
  DropDownFieldType: 'DropDownFieldType',
  MultiSelectFieldType: 'MultiSelectFieldType',
  CheckboxFieldType: 'CheckboxFieldType',
  NumberFieldType: 'NumberFieldType',
  DateFieldType: 'DateFieldType',
  SocialSharingImageFieldType: 'SocialSharingImageFieldType',
  FileUploadFieldType: 'FileUploadFieldType',
  CompanyCoverImageFieldType: 'CompanyCoverImageFieldType',
  JobSalaryRangeFieldType: 'JobSalaryRangeFieldType',
}

export const GENERIC_FIELD = {
  LIST: 'LIST',
  STRING: 'STRING',
  NUMERIC: 'NUMERIC',
  DATE: 'DATE',
}

export const GET_PREDEFINED_FIELDS = [
  // Company
  FIELD_TYPE.CompanyNameFieldType,
  FIELD_TYPE.CompanyLocationFieldType,
  FIELD_TYPE.CompanyWebsiteFieldType,
  FIELD_TYPE.CompanyLogoFieldType,
  FIELD_TYPE.CompanyDescriptionFieldType,
  // Jobs
  FIELD_TYPE.JobTitleFieldType,
  FIELD_TYPE.JobCategoryFieldType,
  FIELD_TYPE.JobTagsFieldType,
  FIELD_TYPE.JobLocationFieldType,
  FIELD_TYPE.JobApplicationMethodFieldType,
  FIELD_TYPE.JobDescriptionFieldType,
]

export const GET_FIELD_GENERIC = {
  // Array<String>
  [FIELD_TYPE.DropDownFieldType]: GENERIC_FIELD.LIST,
  [FIELD_TYPE.MultiSelectFieldType]: GENERIC_FIELD.LIST,
  [FIELD_TYPE.CheckboxFieldType]: GENERIC_FIELD.LIST,
  // String
  [FIELD_TYPE.TextFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.TextAreaFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.RichTextFieldType]: GENERIC_FIELD.STRING,
  // Numeric
  [FIELD_TYPE.NumberFieldType]: GENERIC_FIELD.NUMERIC,
  // Date ?
  [FIELD_TYPE.DateFieldType]: GENERIC_FIELD.DATE,
  // Copmany
  [FIELD_TYPE.CompanyNameFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.CompanyLocationFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.CompanyWebsiteFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.CompanyDescriptionFieldType]: GENERIC_FIELD.STRING,
  // Job
  [FIELD_TYPE.JobTitleFieldType]: GENERIC_FIELD.STRING,
  [FIELD_TYPE.JobDescriptionFieldType]: GENERIC_FIELD.STRING,
}

export const FORM_FIELD_SUGGESTION = {
  [USER_FIELDS]: filterPredefinedFields(
    Object.values(FIELD_TYPE),
    GET_PREDEFINED_FIELDS
  ).filter(excludeAccountForm),
  [COMPANY_PROFILE_FIELDS]: [
    ...filterPredefinedFields(Object.values(FIELD_TYPE), GET_PREDEFINED_FIELDS),
  ].filter(excludeCompanyForm),
  [JOB_POST_FIELDS]: filterPredefinedFields(
    Object.values(FIELD_TYPE),
    GET_PREDEFINED_FIELDS
  ).filter(excludeJobForm),
}

export const FIELD_METADATA = {
  // Array<String>
  [FIELD_TYPE.DropDownFieldType]: {
    name: 'Dropdown',
  },
  [FIELD_TYPE.MultiSelectFieldType]: {
    name: 'Multiselect',
  },
  [FIELD_TYPE.CheckboxFieldType]: {
    name: 'Checkbox',
  },
  // String
  [FIELD_TYPE.TextFieldType]: {
    name: 'Text',
  },
  [FIELD_TYPE.TextAreaFieldType]: {
    name: 'Text Area',
  },
  [FIELD_TYPE.RichTextFieldType]: {
    name: 'Rich Text Editor',
  },
  // Numeric
  [FIELD_TYPE.NumberFieldType]: {
    name: 'Number',
  },
  // Date ?
  [FIELD_TYPE.DateFieldType]: {
    name: 'Date',
  },
  // Predefined Company Profile
  [FIELD_TYPE.CompanyNameFieldType]: {
    name: 'Company Name',
  },
  [FIELD_TYPE.CompanyLocationFieldType]: {
    name: 'Company Location',
  },
  [FIELD_TYPE.CompanyWebsiteFieldType]: {
    name: 'Company Website',
  },
  [FIELD_TYPE.CompanyLogoFieldType]: {
    name: 'Company Logo',
  },
  [FIELD_TYPE.CompanyDescriptionFieldType]: {
    name: 'Company Description',
  },
  // Predefined Job Post
  [FIELD_TYPE.JobTitleFieldType]: {
    name: 'Job Title',
  },
  [FIELD_TYPE.JobCategoryFieldType]: {
    name: 'Job Category',
  },
  [FIELD_TYPE.JobTagsFieldType]: {
    name: 'Job Tags',
  },
  [FIELD_TYPE.JobLocationFieldType]: {
    name: 'Job Location',
  },
  [FIELD_TYPE.JobApplicationMethodFieldType]: {
    name: 'Job Application Method',
  },
  [FIELD_TYPE.JobDescriptionFieldType]: {
    name: 'Job Description',
  },
  // Special
  [FIELD_TYPE.SocialSharingImageFieldType]: {
    name: 'Social Sharing Image',
  },
  [FIELD_TYPE.FileUploadFieldType]: {
    name: 'File',
  },
  [FIELD_TYPE.JobSalaryRangeFieldType]: {
    name: 'Salary Range',
  },
  [FIELD_TYPE.CompanyCoverImageFieldType]: {
    name: 'Company Cover Image',
  },
}

export const OPTION_VALUE_TYPE = {
  StringValue: 1,
  NumericValue: 2,
  DateTimeValue: 3,
  BoolValue: 4,
}

export const GENERIC_OPTION_VALUE = {
  [GENERIC_FIELD.LIST]: OPTION_VALUE_TYPE.StringValue,
  [GENERIC_FIELD.STRING]: OPTION_VALUE_TYPE.StringValue,
  [GENERIC_FIELD.NUMERIC]: OPTION_VALUE_TYPE.NumericValue,
  [GENERIC_FIELD.DATE]: OPTION_VALUE_TYPE.DateTimeValue,
}
