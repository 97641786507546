export const userFreeTrialGuard = (store) => async (to, from, next) => {
  if (!store.getters.getJobBoard) {
    return next()
  }

  const { dateFreeTrialEnds, active } = store.getters.getJobBoard

  if (
    active &&
    dateFreeTrialEnds &&
    localStorage.getItem('auth_token_default') &&
    from === null &&
    to?.path === '/home'
  ) {
    next('/')
    return
  }

  if (
    !active &&
    dateFreeTrialEnds === null &&
    !to.path.startsWith('/start-trial/onboarding')
  ) {
    const keys = ['who-are-you', 'audience', '?']
    const { data } = await store.dispatch('fetchOnboardingQuestions')

    const missingQuestion = keys.map(
      (key) =>
        data.find((value) => value.questionKey === key) || {
          questionKey: key,
          answerKey: null,
        }
    )

    const lastQuestion = missingQuestion.find(
      (value) => value.answerKey === null
    )

    let pageName = lastQuestion.questionKey

    if (lastQuestion.questionKey === '?') {
      let nextPage =
        missingQuestion.at(1).answerKey.option === 'new' ? 'vision' : 'goals'

      if (data.some((value) => value.questionKey === nextPage)) {
        pageName = 'billing'
      } else {
        pageName = nextPage
      }
    }

    next(`/start-trial/onboarding/${pageName}`)
    return
  }

  next()
}
